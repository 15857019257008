
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    await this.loadGoogleInvites();
    this.loading = false;
  },
  watch: {},
  computed: {
    ...mapGetters(['user', 'access', 'permissions', 'googleInviteOptions']),
    isLoading(): boolean {
      return this.loading;
    },
    showSearch(): boolean {
      return false;
    },
    columnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'text',
          columnName: 'inviteId',
          displayName: 'ID',
        },
        {
          type: 'text',
          columnName: 'role',
          displayName: 'Role',
        },
        {
          type: 'text',
          columnName: 'locationName',
          displayName: 'Location',
        },
        {
          type: 'text',
          columnName: 'address',
          displayName: 'Address',
        },
      ];
    },
    tabs(): Tabs {
      return [
        {
          title: 'COUNTRIES',
          route: 'countries',
          activeRouteCheck: '/data/countries',
        },
        {
          title: 'PROVINCES',
          route: 'provinces',
          activeRouteCheck: '/data/provinces',
        },
        {
          title: 'LANGUAGES',
          route: 'languages',
          activeRouteCheck: '/data/languages',
        },
        {
          title: 'INVITES',
          route: 'invites',
          activeRouteCheck: '/data/invites',
        },
      ];
    },
    tableData(): unknown {
      return this.googleInviteOptions;
    },
    tableOptions(): any {
      return {
        clickable: true,
        actions: true,
      };
    },
  },

  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'languageId', type: 'text', direction: 'ASC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'loadGoogleInvites',
      'acceptGoogleInvite',
      'declineGoogleInvite',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'resetPaginationState',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    async actionClicked(event: any): Promise<void> {
      alert(event.row.inviteId);
      if (event.type === 'accepted') {
        await this.acceptGoogleInvite({
          inviteId: event.row.inviteId,
        });
      }

      if (event.type === 'declined') {
        await this.declineGoogleInvite({
          inviteId: event.row.inviteId,
        });
      }
    },
  },
});
